<template>
  <v-dialog v-model="dialog" max-width="600">
    <v-card class="rounded-lg pa-6" color="neutral2">
      <div class="d-flex align-center mb-6">
        <div class="text-h6">Personalizar estilo</div>

        <v-spacer />

        <v-btn icon small @click="close()">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </div>

      <section>
        <v-form v-model="valid" ref="form">
          <v-row no-gutters>
            <v-col cols="12" md="6" class="pr-md-2">
              <ColorPicker
                v-model="form.colors.primary"
                :label="'Cor primária'"
                :startValue="form.colors.primary"
                :rules="_rulesColor"
              />
            </v-col>

            <v-col cols="12" md="6" class="pl-md-2">
              <ColorPicker
                v-model="form.colors.secondary"
                :label="'Cor secondária'"
                :startValue="form.colors.secondary"
                :rules="_rulesColor"
              />
            </v-col>
          </v-row>

          <v-switch
            v-model="form.verticalVideo"
            label="Vídeo vertical"
            class="ma-0 ml-4"
          />

          <v-switch
            v-model="form.border.show"
            label="Borda do vídeo"
            class="ma-0 ml-4"
          />

          <v-card v-if="form.border.show" class="rounded-lg mb-4" outlined flat>
            <v-card class="pa-4 rounded-lg" color="neutral2" flat>
              <ColorPicker
                v-model="form.border.color"
                :label="'Cor da borda'"
                :startValue="form.border.color"
                :rules="_rulesColor"
              />

              <div class="text-body-1 mx-2">
                Largura da borda: {{ form.border.size }}px
              </div>

              <v-slider
                v-model="form.border.size"
                max="24"
                min="1"
                :thumb-label="false"
                :thumb-size="24"
                hide-details
              />

              <v-checkbox
                v-model="form.border.rounded"
                label="Borda arredondada"
              />
            </v-card>
          </v-card>

          <v-list class="pa-0 transparent mb-4">
            <v-list-item
              v-for="(item, i) in _switchs"
              :key="i"
              @click="form[item.key] = !form[item.key]"
            >
              <v-list-item-action>
                <v-checkbox :input-value="form[item.key]" />
              </v-list-item-action>

              <v-list-item-content>
                <v-list-item-title>{{ item.title }}</v-list-item-title>
                <v-list-item-subtitle v-if="item.subtitle">
                  {{ item.subtitle }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>

          <div class="d-flex">
            <v-spacer />

            <v-btn class="text-none mr-4" text @click="close()">
              <span> Cancelar </span>
            </v-btn>

            <v-btn
              class="text-none black--text"
              color="primary"
              @click="submit()"
            >
              <span> Salvar alterações </span>
            </v-btn>
          </div>
        </v-form>
      </section>

      <loader-hover v-if="loading" />
    </v-card>
  </v-dialog>
</template>

<script>
import { request } from "@/services";
import ColorPicker from "@/components/colorPicker/ColorPicker.vue";

export default {
  components: {
    ColorPicker,
  },

  data() {
    return {
      dialog: false,
      valid: false,
      loading: false,
      videoID: null,
      form: {
        colors: {
          primary: "",
          secondary: "",
        },
        border: {
          show: true,
          color: "",
          size: "8",
          rounded: false,
        },
        biggerPlayBtn: true,
        playBtn: true,
        progressBar: true,
        timer: true,
        back: true,
        advance: true,
        volume: true,
        fullscreen: true,
      },
    };
  },

  props: {
    video: { type: Object, required: true },
  },

  computed: {
    _rules() {
      return [(v) => !!v || "Campo Obrigatório"];
    },

    _rulesColor() {
      return [
        (v) => !!v || "Campo obrigatório",
        (v) =>
          /^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/.test(v) ||
          "Hexadecimal inválido",
      ];
    },

    _switchs() {
      return [
        /* {
          key: "biggerPlayBtn",
          title: "Botão de controle grande",
          subtitle: "Exibir botão de iníciar/pausar grande",
        }, */
        {
          key: "playBtn",
          title: "Botão de pausa",
          subtitle: "Exibir botão de pausa",
        },
        {
          key: "progressBar",
          title: "Barra de progresso",
          subtitle: "Exibir barra de progresso do vídeo",
        },
        {
          key: "timer",
          title: "Tempo do vídeo",
          subtitle: "Exibir tempo do vídeo",
        },
        /*  {
          key: "back",
          title: "Botão voltar",
          subtitle: "Exibir botão de voltar 10s",
        },
        {
          key: "advance",
          title: "Botão avançar 10s",
          subtitle: "Exibir botão de avançar 10s",
        }, */
        {
          key: "volume",
          title: "Volume",
          subtitle: "Exibir controle de volume",
        },
        {
          key: "fullscreen",
          title: "Tela cheia",
          subtitle: "Exibir função de tela cheia",
        },
      ];
    },
  },

  methods: {
    async submit() {
      if (!this.$refs.form.validate()) return;

      try {
        this.loading = true;

        const payload = {
          method: "uploadVideoConfig",
          videoID: this.videoID,
          corPrincipal: this.form.colors.primary.toUpperCase(),
          corSecundaria: this.form.colors.secondary.toUpperCase(),
          ativaBorda: this.form.border.show ? "1" : "0",
          corBorda: this.form.border.color,
          tamBorda: this.form.border.size,
          bordaArredondada: this.form.border.rounded ? "1" : "0",
          playGrande: this.form.biggerPlayBtn ? "1" : "0",
          playPequeno: this.form.playBtn ? "1" : "0",
          barraProgresso: this.form.progressBar ? "1" : "0",
          tempoVideo: this.form.timer ? "1" : "0",
          voltar10s: this.form.back ? "1" : "0",
          avancar10s: this.form.advance ? "1" : "0",
          volume: this.form.volume ? "1" : "0",
          fullScreen: this.form.fullscreen ? "1" : "0",
          videoVertical: this.form.verticalVideo ? "1" : "0",
        };

        await request(payload);

        this.handleAlert("Salvo com sucesso", "success");
        this.$emit("success");
        this.close();
      } catch (err) {
        this.handleAlert(err.data.message, "error");
      } finally {
        this.loading = false;
      }
    },

    handleAlert(title, type) {
      this.$root.$emit("alert", { title, type });
    },

    open() {
      this.form = structuredClone(this.video.settings);
      this.videoID = this.video.id;
      this.dialog = true;
    },

    close() {
      this.dialog = false;
    },
  },
};
</script>

<style src="./style.scss" lang="scss" scoped />
