<template>
  <v-dialog v-model="dialog" max-width="800">
    <v-card class="rounded-lg pa-6" color="neutral2">
      <div class="d-flex align-center mb-6">
        <div class="text-h6">Incorpore o seu vídeo</div>

        <v-spacer />

        <v-btn icon small @click="close()">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </div>

      <div class="d-flex align-center mb-4">
        <div class="text-body-1 font-weight-regular">
          Coloque no código-fonte do seu site
        </div>

        <v-spacer />

        <v-btn
          class="text-none"
          color="primary"
          small
          text
          @click="copy(embed)"
        >
          Copiar <v-icon right>mdi-content-copy</v-icon>
        </v-btn>
      </div>

      <v-textarea
        v-model="embed"
        class="rounded-lg mb-8"
        hide-details
        auto-grow
        rounded
        filled
        readonly
        @click="copy(embed)"
      />

      <section v-if="videoURL">
        <div class="d-flex align-center mb-4">
          <div class="text-body-1 font-weight-regular">
            Ou se preferir, copie diretamente a URL do vídeo
          </div>

          <v-spacer />

          <v-btn
            class="text-none"
            color="primary"
            small
            text
            @click="copy(videoURL)"
          >
            Copiar <v-icon right>mdi-content-copy</v-icon>
          </v-btn>
        </div>

        <v-textarea
          v-model="videoURL"
          class="rounded-lg"
          rows="3"
          hide-details
          auto-grow
          rounded
          filled
          readonly
          @click="copy(videoURL)"
        />
      </section>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState } from "vuex";

export default {
  data() {
    return {
      dialog: false,
      video: null,
      videoURL: "",
      embed: "",
    };
  },

  computed: {
    ...mapState(["user"]),
  },

  methods: {
    open(data) {
      const randomNumber = Math.floor(Math.random() * 1e16)
        .toString()
        .padStart(16, "0");

      const vertical = data.settings.verticalVideo;
      const aspectRatio = vertical ? 177.78 : 62.5; // 9/16 ≈ 177.78%, 16/9 ≈ 62.5%

      this.video = data;

      this.embed = `<style>.video-wrapper{position: relative; padding-bottom: ${aspectRatio}%; height: 0; overflow: hidden; max-width: 100%; margin: 0 auto;}.video-wrapper iframe, .video-wrapper div{position: absolute; top: 0; left: 0; width: 100%; height: 100%;}  @media (max-width: 600px) {.video-wrapper {min-height: 250px;}}</style><div class="video-wrapper"><script src="https://api.myvsl.com.br/js/myvsl.js?v=${randomNumber}" onload="loadPlayer('${data.videoToken}', '${this.user.userToken}');" ><\/script><div id="vid_${data.videoToken}"></div> </div>`;
      this.videoURL = `https://embedded.myvsl.com.br?h=${randomNumber}0&vt=${data.videoToken}&ut=${this.user.userToken}&vertical=${vertical}`;

      this.dialog = true;
    },

    close() {
      this.dialog = false;
    },

    copy(value) {
      navigator.clipboard.writeText(value);
      this.handleAlert("Copiado para área de transferência", "info");
    },

    handleAlert(title, type) {
      this.$root.$emit("alert", { title, type });
    },
  },
};
</script>

<style src="./style.scss" lang="scss" scoped />
